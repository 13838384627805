import React from 'react';
import ReactDOM from 'react-dom';
import { RMWCProvider } from '@rmwc/provider';

import App from './app/App';
import './index.css';
import '../node_modules/material-components-web/dist/material-components-web.min.css';
import './app/App.css';
import './app/responsive.css';

ReactDOM.render(
  <RMWCProvider ripple={false}>
    <App />
  </RMWCProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// Register the service worker in the main file
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      const registration = await navigator.serviceWorker.register('/notification-sw.js', { scope: '/' });
      console.log('Service Worker registered with scope:', registration.scope);
    } catch (error) {
      console.error('Service Worker registration failed:', error);
    }
  });
}

if (module != null && module.hot) {
  module.hot.accept();
}
