import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IconButton } from '@rmwc/icon-button';
import LoadingSpinner from '../shared/LoadingSpinner';
import ChatRoomRow from './ChatRoomRow';
import { appcontentsize } from '../styles/inline-styles';

const rearrangeRooms = (currentUser, rooms) => [
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] != null ? room : null)),
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] == null ? room : null)),
].filter((item) => item != null);

const renderChatrooms = (currentUser, rooms, unreadMessages) => {
  const chatRooms = rearrangeRooms(currentUser, rooms || []);
  return (
    <div {...{
      style: appcontentsize,
    }}
    >
      {
      chatRooms.length > 0 ? (
        chatRooms.map((room) => (
          <ChatRoomRow
            key={`chat-room-${room.id}`}
            room={room}
            unreadMessages={unreadMessages[room.id] != null ? unreadMessages[room.id].length : 0}
          />
        ))) : (
          <p
            style={{ textAlign: 'center' }}
          >
            Viestiryhmiä ei löytynyt
          </p>
      )
      }
    </div>
  );
};

@inject('uiStore', 'chatStore', 't', 'notificationStore')
@observer
class Chats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      feedbackMessage: '',
    };

    const { chatStore } = this.props;
    chatStore.refreshRooms();
  }

  async handleNotificationToggle() {
    const { notificationStore } = this.props;

    this.setState({ loading: true, feedbackMessage: '' });

    try {
      if (notificationStore.isSubscribed) {
        // Disable notifications
        await notificationStore.unsubscribeUser();
        this.setState({ feedbackMessage: 'Ilmoitukset poistettu käytöstä' });
      } else {
        // Enable notifications
        await notificationStore.subscribeUser();
        this.setState({ feedbackMessage: 'Ilmoitukset otettu käyttöön' });
      }
    } catch (error) {
      this.setState({ feedbackMessage: 'Tapahtui virhe, yritä uudelleen' });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { chatStore, uiStore: { currentUser }, notificationStore } = this.props;
    const { rooms, unreadMessages } = chatStore;
    const { loading, feedbackMessage } = this.state;

    return (
      <>
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <IconButton
                style={{ color: 'white' }}
                icon={notificationStore.isSubscribed ? 'notifications_off' : 'notifications'}
                onClick={() => this.handleNotificationToggle()}
              />
              {' '}
              {notificationStore.isSubscribed ? 'Poista ilmoitukset käytöstä' : 'Ota ilmoitukset käyttöön'}
            </>
          )}
          <p>{feedbackMessage}</p>
        </>
        {rooms.case({
          pending: () => <p>Ladataan viestejä...</p>,
          rejected: () => <p>Viestejä ei ole ladattu, yritä kohta uudelleen!</p>,
          fulfilled: () => renderChatrooms(currentUser, rooms.value, unreadMessages),
        })}
        <p
          style={{ fontSize: '12px', margin: 'auto 0 20px 0' }}
        >
          Viestiryhmät piilotetaan 15 päivän päästä keikan päättymisestä
        </p>
      </>
    );
  }
}

export default Chats;
