/* global Notification */
import { observable, action, runInAction } from 'mobx';
import { urlBase64ToUint8Array } from '../utils/urlUtils';


class NotificationStore {
  // Subscription related observables
  @observable subscription = null;

  @observable isSubscribed = false;

  @observable notificationPermission = null;

  // Push notification related observables, not in use in the first version
  /*
  @observable pushNotifications = [];

  @observable mutedNotifications = [];
  */

  constructor(requests, uiStore) {
    this.requests = requests;
    this.uiStore = uiStore;
  }

  @action setSubscription(subscription) {
    this.subscription = subscription;
    this.isSubscribed = true;
  }

  @action clearSubscription() {
    this.subscription = null;
    this.isSubscribed = false;
  }

  // This method is called in the App component so it can straight away check if the user is subscribed
  @action async checkExistingSubscription() {
    const registration = await navigator.serviceWorker.ready;
    const existingSubscription = await registration.pushManager.getSubscription();

    if (existingSubscription) {
      // If a subscription exists, store it in the NotificationStore
      this.setSubscription(existingSubscription);
    } else {
      // If no existing subscription, clear the current subscription and ensure server-side cleanup
      this.clearSubscription();
      await this.deleteSubscriptionFromServer();
    }
  }

  // Utility function to get or create a device identifier
  // TODO: This is currently lightly used, but can be used to identify devices for push notifications, this is more like a browser identifier than a device identifier
  // Also, this might not be optimal, but it's good enough for this example, since we're not storing any sensitive information and we're not using this for anything critical
  getOrCreateDeviceIdentifier = () => {
    let deviceId = localStorage.getItem('device_id');

    if (!deviceId) {
      deviceId = `device-${Math.random().toString(36).substring(2, 18)}`;
      localStorage.setItem('device_id', deviceId);
    }

    return deviceId;
  };

  // METHODS RELATED TO SUBSCRIPTIONS
  // Ask for permission from the browser to send notifications
  @action async askPermission() {
    const permissionResult = await Notification.requestPermission();
    if (permissionResult !== 'granted') {
      throw new Error('Permission not granted for Notification');
    }
    runInAction(() => {
      this.notificationPermission = permissionResult;
    });
  }

  @action async subscribeUser() {
    const registration = await navigator.serviceWorker.ready;
    const { currentUser, currentUser: { vapidPublicKey } } = this.uiStore;
    console.log(currentUser);
    console.log('Public Vapid Key:', vapidPublicKey);

    // Check if there's already a subscription
    const existingSubscription = await registration.pushManager.getSubscription();

    if (existingSubscription) {
      // The user is already subscribed, no need to resubscribe
      return existingSubscription;
    }

    // Create a new subscription
    try {
      const newSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
      });

      // Send subscription to the backend
      const response = await this.sendSubscriptionToServer(newSubscription);

      // Only store it if the backend successfully saves it
      if (!response.success) {
        // If backend fails, unsubscribe immediately
        await newSubscription.unsubscribe();
        throw new Error('Failed to save subscription in the database');
      }
      this.setSubscription(newSubscription);
      this.isSubscribed = true;
      return newSubscription;
    } catch (error) {
      console.error('Subscription creation failed:', error);
      throw error;
    }
  }

  @action async sendSubscriptionToServer(subscription) {
    const { keys } = subscription.toJSON();
    const body = {
      endpoint: subscription.endpoint,
      p256dh: keys.p256dh,
      auth: keys.auth,
      device_id: this.getOrCreateDeviceIdentifier(),
    };

    // Send a POST request to create a new subscription
    return this.requests.Notifications.addSubscription({ subscription: body });
  }

  @action async unsubscribeUser() {
    const registration = await navigator.serviceWorker.ready;

    try {
      const subscription = await registration.pushManager.getSubscription();
      console.log('Subscription to unsubscribe:', subscription);
      if (subscription) {
        // Unsubscribe from the push manager
        await subscription.unsubscribe();
        console.log('Unsubscribed from push notifications');

        // Send a request to the server to delete the subscription
        await this.deleteSubscriptionFromServer(subscription.endpoint);

        runInAction(() => {
          this.subscription = null;
          this.isSubscribed = false;
        });
      }
    } catch (error) {
      console.error('Failed to unsubscribe from push notifications:', error);
    }
  }

  @action async deleteSubscriptionFromServer(subscriptionEndpoint) {
    // Create a new variable instead of reassigning the parameter
    const endpoint = subscriptionEndpoint || (this.subscription && this.subscription.endpoint);

    if (endpoint) {
      try {
        await this.requests.Notifications.deleteSubscriptionByEndpoint(endpoint);
        console.log('Subscription deleted from server');
      } catch (error) {
        console.error('Failed to delete subscription from server:', error);
      }
    } else {
      console.warn('No subscription endpoint to delete from the server');
    }
  }


  // ALL PUSH  NOTIFICATIONS RELATED METHODS, NOT USED IN THE FIRST VERSION
  // TODO: Implement these methods if you want to enable muting specific notifications.
/*

  @action async fetchPushNotifications() {
    try {
      const response = await this.requests.Notifications.fetchAll();
      runInAction(() => {
        this.pushNotifications = response.data;
      });
    } catch (error) {
      console.error('Failed to fetch push notifications:', error);
    }
  }

  @action async muteNotification(pushNotificationId) {
    try {
      await this.requests.Notifications.mute(pushNotificationId);
      runInAction(() => {
        this.mutedNotifications.push(pushNotificationId);
        this.pushNotifications = this.pushNotifications.filter(
          (notification) => notification.id !== pushNotificationId,
        );
      });
    } catch (error) {
      console.error('Failed to mute notification:', error);
    }
  }

  @action async sendPushNotification(subscriptionId, targetType, targetId) {
    const body = {
      subscription_id: subscriptionId,
      target_type: targetType,
      target_id: targetId,
    };

    try {
      await this.requests.Notifications.sendPushNotification(body);
    } catch (error) {
      console.error('Failed to send push notification:', error);
    }
  }
  */
}

export default NotificationStore;
