import { action, computed, observable } from 'mobx';
import { set } from 'lodash';
import moment, { dateWithoutTime, cleanDate } from '../utils/moment';
import WorkHourBank from './WorkHourBank';
import Validity from './Validity';
import AbsencePeriod from './AbsencePeriod';
import AbsenceType from './AbsenceType';
import AutocompleteLocation from '../timelog/AutocompleteLocation';

// Valtti card is hardcoded for accounts 2, 7 and 10
// Road safety card is hardcoded for accounts 7 and 10
// Machinery permit is hardcoded for accounts 1 and 10
export const mandatoryCardTypes = [
  'firstAidTraining',
  'hotWorkLicense',
  'occupationalSafetyCard',
  'valttiCard',
  'roadSafetyCard',
  'machineryPermit',
  'ssgCard',
  'id06Card',
];
export const cardTypes = [
  ...mandatoryCardTypes,
  'drivingLicense',
];

// DO THE CONVERSION HERE
// BUT ALSO DO ANOTHER CONVERSION IN THE INPUT FIELD TO CHECK IF WE SHOULD SAVE OR NOT
// const convertDetailCardDates = (details) => {
//   const updatedDetails = { ...details };
//   const updatedDetailCards = { ...updatedDetails.cards };

//   Object.keys(updatedDetailCards).forEach((key) => {
//     const updatedCard = updatedDetailCards[key];
//     const convertedDate = Validity.convertDate(updatedCard.validThrough);

//     if (convertedDate.isValid) {
//       updatedDetailCards[key] = {
//         ...updatedCard,
//         validThrough: convertedDate.dateObject,
//         validThroughDateFormat: convertedDate.formatIndex,
//       };
//     }
//   });

//   return { ...updatedDetails, cards: updatedDetailCards };
// };

const mapToUnixDate = (daySet) => [...daySet].map((unix) => moment.unix(unix));

export const workOrderDaysInMonth = (data) => {
  const busyDays = new Set();

  data.forEach((wo) => {
    if (!wo.isNotBusy && wo.workDays) {
      wo.workDays.forEach((workDay) => {
        busyDays.add(workDay.from.unix());
      });
    }
  });

  return {
    busyDays: mapToUnixDate(busyDays),
  };
};

export const availabilityDaysInMonth = (data) => {
  const availableDays = new Set();
  const busyDays = new Set();

  // Make a copy so we don't trigger rendering from sorting
  const copy = data.slice()
    .map((item) => ({
      interval: (item.interval != null ? item.interval : moment.interval(item.from, item.to)),
      available: item.available,
    }))
    .sort((a, b) => a.interval.start.valueOf() - b.interval.start.valueOf());

  let begin;
  copy.forEach((availability) => {
    const { available, interval: { end, start } } = availability;

    begin = start;
    for (
      ;
      begin.isSameOrBefore(end);
      begin = dateWithoutTime(begin).add(1, 'days').startOf('day')
    ) {
      if (available) {
        availableDays.add(begin.unix());
      } else {
        busyDays.add(begin.unix());
      }
    }
  });

  return {
    availableDays: mapToUnixDate(availableDays),
    busyDays: mapToUnixDate(busyDays),
  };
};

export default class User {
  @observable attachments;

  @observable availability;

  @observable bankNumber;

  @observable city;

  @observable country;

  @observable details;

  @observable driversLicenseUrl;

  @observable email;

  @observable firstName;

  @observable id;

  @observable identificationPictureUrl;

  @observable invitations;

  @observable lastName;

  @observable personalPictureUrl;

  @observable phoneNumber;

  @observable role;

  @observable settings;

  @observable socialSecurityNumber;

  @observable street;

  @observable taxIncomeCardUrl;

  @observable taxNumber;

  @observable taxPercent;

  @observable workOrders = [];

  @observable archivedWorkOrders = [];

  @observable employerWorkOrders = [];

  // @observable workOrdersUrl;

  @observable zipCode;

  @observable accountId;

  @observable helmetImageUrl;

  @observable workOrderTrips = [];

  @observable autocompleteLocations = [];

  @observable products = [];

  // DEACTIVATED PRODUCTS
  @observable userProductCollections = [];

  @observable workHourBank;

  @observable workHourBankEnabled;

  @observable iceName;

  @observable iceNumber;

  @observable validityTargets = {};

  @observable validities = [];

  @observable birthDate;

  @observable calendarAvailabilities = [];

  @observable availableDays = [];

  @observable busyDays = [];

  @observable workDays = [];

  @observable lastSalaryPeriodDays = [];

  // @observable machineryPermitUrl;

  @observable active;

  @observable permissions = [];

  @observable externalId;

  @observable externalGroup;

  @observable subcontractor;

  @observable accountInfo = {};

  // Multi-account prototype (one user has many accounts)
  @observable accounts = [];

  // Combine with accountInfo?
  @observable currentAccount = {};

  @observable salaryPeriodCategory = {};

  @observable absencePeriods = [];

  @observable absencesEnabled;

  @observable fullName;

  @observable vapidPublicKey;

  constructor(object) {
    this.updateProperties(object);
  }

  isEmployee() {
    return this.role === 'employee';
  }

  isSameUser(user) {
    return this.id === user.id;
  }

  getFullname() {
    return `${this.lastName}, ${this.firstName}`;
  }

  getFullNameShort() {
    return `${this.firstName} ${this.lastName[0]}`;
  }

  hasPermission(resourceName, attemptedAction) {
    return this.permissions.find((permission) => permission.resource_name === resourceName && permission.action === attemptedAction);
  }

  homeAddress() {
    if (this.street && this.city) {
      return AutocompleteLocation.fromJsonProperties({
        street: this.street,
        zip_code: this.zipCode,
        city: this.city,
        name: 'Koti',
      });
    }
    return null;
  }

  @computed get hasCompletedRegistration() {
    const mandatoryFields = [
      'firstName',
      'lastName',
      'phoneNumber',
      'socialSecurityNumber',
      'taxNumber',
      'bankNumber',
    ];

    return mandatoryFields.every((field) => this[field] != null);
  }

  @action rejectInvitation(toReject) {
    const invitation = this.invitations.find((i) => toReject.id === i.id);
    invitation.canParticipate = false;
  }

  @action acceptInvitation(toAccept) {
    const invitation = this.invitations.find((i) => toAccept.id === i.id);
    invitation.canParticipate = true;
  }

  @action updateWorkOrder(workOrder) {
    // hack to get UI refreshed
    const index = this.workOrders.findIndex((item) => item.id === workOrder.id);
    this.workOrders[index] = { ...workOrder };
  }

  hasSetAvailabilityForMonth(month) {
    return this.availability.some(
      (av) => av.from.isSame(month, 'month') || av.to.isSame(month, 'month'),
    );
  }

  setCalendarAvailabilities() {
    const daySets = availabilityDaysInMonth(this.availability);
    this.availableDays.replace(daySets.availableDays.map(cleanDate));
    this.busyDays.replace(daySets.busyDays.map(cleanDate));

    const workOrderDaySets = workOrderDaysInMonth(this.workOrders);
    this.workDays.replace(workOrderDaySets.busyDays.map(cleanDate));
  }

  // Supports updating nested attributes, e.g. "currentAccount.subcontractor" as attr
  @action changeAttribute(attr, value) {
    // this[attr] = value;
    set(this, attr, value);
  }

  static toJson(o) {
    const json = {
      bank_number: o.bankNumber,
      city: o.city,
      country: o.country,
      details: o.details, // convertDetailCardDates({ ...o.details }),
      email: o.email,
      first_name: o.firstName,
      id: o.id,
      last_name: o.lastName,
      phone_number: o.phoneNumber,
      role: o.role,
      settings: o.settings,
      social_security_number: o.socialSecurityNumber,
      street: o.street,
      tax_number: o.taxNumber,
      tax_percent: o.taxPercent,
      zip_code: o.zipCode,
      account_id: o.accountId,
      ice_name: o.iceName,
      ice_number: o.iceNumber,
      extid: o.externalId,
      extgroup: o.externalGroup,
      active: o.active,
      subcontractor: o.subcontractor,
      user_accounts_attributes: [o.currentAccount],
      salary_period_category_id: o.salaryPeriodCategory?.id,
      // tax_income_url: this.taxIncomeCardUrl //, [TODO-Aleksi: Uncomment for helmet removal]
      // helmet_image_url: this.helmetImageUrl, [TODO-Aleksi: Uncomment for helmet removal]
    };

    // For creating a new user without registration by employers
    if (!o.id && o.password) {
      json.password = o.password;
    }

    return json;
  }

  @action updatePropertiesFromJson(object) {
    let newData = {
      email: object.email,
      firstName: object.first_name,
      id: object.id,
      lastName: object.last_name,
      personalPictureUrl: object.personal_picture_url,
      phoneNumber: object.phone_number,
      accountId: object.account_id,
      birthDate: object.birth_date,
    };

    if (object._private === true) {
      const invitations = object.work_order_invitations.map(
        (invitation) => ({
          canParticipate: invitation.can_participate,
          description: invitation.work_order.description,
          id: invitation.id,
          interval: moment.interval(invitation.work_order.from, invitation.work_order.to),
          inviteDeadline: moment(invitation.invite_deadline),
          isInvitation: true,
          location: invitation.location,
          moreInfo: invitation.more_info,
          name: invitation.work_order.name,
          purchaser: invitation.purchaser.name,
          workOrderId: invitation.work_order.id,
          calendarEntries: invitation.calendar_entries,
          previousCalendarEntries: invitation.previous_calendar_entries,
        }),
      );

      const availability = object.availability.map(
        (av) => ({
          available: av.available,
          createdAt: moment(av.created_at),
          from: dateWithoutTime(av.from),
          id: av.id,
          to: dateWithoutTime(av.to),
        }),
      );

      const workHourBank = WorkHourBank.fromJsonProperties(object.work_hour_bank);

      /* eslint-disable no-param-reassign */
      newData.details = {
        bankName: null,
        cards: {
          drivingLicense: {
            class: null,
          },
        },
        clothing: {
          size: null,
          number: null,
          hasHelmet: null,
          helmetSize: null,
          shoeSize: null,
        },
        paymentDetails: null,
      };

      cardTypes.forEach((type) => {
        newData.details.cards[type] = {};
      });

      const validities = object.validities.map((validityObject) => Validity.fromJsonProperties(validityObject));

      if (object.details != null) {
        newData.details.bankName = object.details.bankName || null;
        newData.details.paymentDetails = object.details.paymentDetails || {};

        if (object.details.clothing != null) {
          // Clothing data for user.
          newData.details.clothing.size = object.details.clothing.size || null;
          newData.details.clothing.number = object.details.clothing.number || null;
          newData.details.clothing.hasHelmet = object.details.clothing.hasHelmet || false;
          newData.details.clothing.helmetSize = object.details.clothing.helmetSize || null;
          newData.details.clothing.shoeSize = object.details.clothing.shoeSize || null;
        }

        if (object.details.cards != null) {
          let card;
          cardTypes.forEach((type) => {
            card = object.details.cards[type] || {};
            newData.details.cards[type] = card || {};
            newData.details.cards[type].imageURL = card.imageURL || null;
            newData.details.cards[type].number = card.number || null;
            newData.details.cards[type].validThrough = card.validThrough || null;
            // Backwards compatibility: old cards won't have validThroughFormat, always use mm/yyyy format instead
            // newData.details.cards[type].validThrough = card.validThroughDateFormat ? Validity.convertDateToOriginalInput(card.validThrough, card.validThroughDateFormat) : card.validThrough || null;
          });
          newData.details.cards.drivingLicense.class = object.details.cards.drivingLicense?.class || null;
        }
      }
      newData = {
        ...newData,
        availability,
        invitations,
        invitationsByWorkOrderId: invitations.reduce((acc, curr) => {
          acc[curr.workOrderId] = curr;
          return acc;
        }, {}),
        bankNumber: object.bank_number,
        city: object.city,
        country: object.country,
        email: object.email,
        firstName: object.first_name,
        id: object.id,
        lastName: object.last_name,
        personalPictureUrl: object.personal_picture_url,
        phoneNumber: object.phone_number,
        role: object.role,
        settings: object.settings,
        socialSecurityNumber: object.social_security_number,
        street: object.street,
        taxIncomeCardUrl: object.taxation_income_card_url,
        taxNumber: object.tax_number,
        taxPercent: object.tax_percent,
        work_order_url: object.work_order_url,
        zipCode: object.zip_code,
        helmetImageUrl: object.helmet_image_url,
        workHourBank,
        workHourBankEnabled: object.work_hour_bank_enabled,
        // validityTargets: object.validity_targets,
        validities,
        accountInfo: object.account_info,
        iceName: object.ice_name,
        iceNumber: object.ice_number,
        // machineryPermitUrl: object.details.cards.machineryPermit?.imageURL,
        // Multi-account prototype (one user has many accounts)
        accounts: object.accounts,
        permissions: object.permissions,
        absencePeriods: object.absence_periods.map(AbsencePeriod.fromJsonProperties),
        absenceTypes: object.absence_types.map(AbsenceType.fromJsonProperties),
        // If user is themselves: match the currently active account
        // If the user is someone else: should be null
        currentAccount: object.user_account,
        // salaryPeriodCategory: object.salary_period_category,
        fullName: object.first_name && object.last_name ? `${object.last_name}, ${object.first_name}` : null,
        vapidPublicKey: object.public_vapid_key,
      };
    }
    this.updateProperties(newData);
  }

  @action updatePropertiesFromJsonEmployer(object) {
    const validities = object.validities.map((validityObject) => Validity.fromJsonProperties(validityObject));

    const newData = {
      id: object.id,
      email: object.email,
      firstName: object.first_name,
      lastName: object.last_name,
      personalPictureUrl: object.personal_picture_url,
      phoneNumber: object.phone_number,
      accountId: object.account_id,
      birthDate: object.birth_date,
      details: {
        bankName: null,
        cards: {
          drivingLicense: {
            class: null,
          },
        },
        clothing: {
          size: null,
          number: null,
          hasHelmet: null,
          helmetSize: null,
          shoeSize: null,
        },
        paymentDetails: null,
      },
      validities,
      bankNumber: object.bank_number,
      city: object.city,
      country: object.country,
      role: object.role,
      settings: object.settings,
      socialSecurityNumber: object.social_security_number,
      street: object.street,
      taxIncomeCardUrl: object.taxation_income_card_url,
      taxNumber: object.tax_number,
      taxPercent: object.tax_percent,
      zipCode: object.zip_code,
      helmetImageUrl: object.helmet_image_url,
      iceName: object.ice_name,
      iceNumber: object.ice_number,
      active: object.active,
      externalId: object.extid,
      externalGroup: object.extgroup,
      subcontractor: object.subcontractor,
      // Should have the user_account matching the employer's current_account
      currentAccount: object.user_account,
      salaryPeriodCategory: object.salary_period_category,
      fullName: object.first_name && object.last_name ? `${object.last_name}, ${object.first_name}` : null,
    };

    cardTypes.forEach((type) => {
      newData.details.cards[type] = {};
    });

    if (object.details != null) {
      newData.details.bankName = object.details.bankName || null;
      newData.details.paymentDetails = object.details.paymentDetails || {};

      if (object.details.clothing != null) {
        // Clothing data for user.
        newData.details.clothing.size = object.details.clothing.size || null;
        newData.details.clothing.number = object.details.clothing.number || null;
        newData.details.clothing.hasHelmet = object.details.clothing.hasHelmet || false;
        newData.details.clothing.helmetSize = object.details.clothing.helmetSize || null;
        newData.details.clothing.shoeSize = object.details.clothing.shoeSize || null;
      }

      if (object.details.cards != null) {
        let card;
        cardTypes.forEach((type) => {
          card = object.details.cards[type] || {};
          newData.details.cards[type] = card || {};
          newData.details.cards[type].imageURL = card.imageURL || null;
          newData.details.cards[type].number = card.number || null;
          newData.details.cards[type].validThrough = card.validThrough || null;
          // Backwards compatibility: old cards won't have validThroughFormat, always use mm/yyyy format instead
          // newData.details.cards[type].validThrough = card.validThroughDateFormat ? Validity.convertDateToOriginalInput(card.validThrough, card.validThroughDateFormat) : card.validThrough || null;
        });
        newData.details.cards.drivingLicense.class = object.details.cards.drivingLicense?.class || null;
      }
    }

    this.updateProperties(newData);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action purgeDetailCardProp(card) {
    this.details.cards[card] = { imageURL: null, number: null, validThrough: null };
  }

  @action setSalaryPeriods(json) {
    this.salaryPeriods = json;
    this.currentSalaryPeriod = json.find((sp) => sp.isCurrent);
    this.lastSalaryPeriodDays = json.map((item) => cleanDate(moment(item.to, 'YYYY-MM-DD')));
  }

  static fromJsonProperties(object, mode = null) {
    const user = new User({});
    if (mode === 'employer') {
      user.updatePropertiesFromJsonEmployer(object);
    } else {
      user.updatePropertiesFromJson(object);
    }

    return user;
  }

  static fromJson(json) {
    return User.fromJsonProperties(json instanceof Object ? json : JSON.parse(json));
  }
}
